<template>
  <div class="email-create-step">
    <div class="">
      <div class="card builder">
        <div class="card-body">
          <ValidationObserver v-slot="{ handleSubmit }" ref="broadcastForm">
            <form v-if="broadcast" class="authentication-form" @submit.prevent="handleSubmit(onSendBroadcast)">
              <div v-if="!broadcastEditable" class="d-flex justify-content-between align-items-center">
                <span class="text-danger d-block mb-2 font-weight-bold">You can't edit this
                  broadcast, It's already processed!</span>
                <router-link :to="{ name: 'business.broadcasts.duplicate', params: { id: broadcast.id } }" class="btn btn-primary">
                  <i class="uil uil-upload-alt"></i> Duplicate
                </router-link>
              </div>
              <p v-if="resendEditable" class="text-primary font-weight-bold text-left"><i><span v-if="resendType === 1">Send to
                    new contacts (since this campaign was originally sent)</span><span v-else>Send to contacts who have
                    not read/opened this campaign</span></i></p>
              <p v-if="showLimitNotification" class="text-danger font-weight-bold text-left"><i><span>Account Limit Reached: To send a broadcast to more than <b>{{ business.email_limit_per_month | number }}</b> contacts,
                please upgrade your account. Contact us at <a :href="`mailto:${agencyEmail}`" target="_blank"><u>{{ agencyEmail }}</u></a> to upgrade your account</span></i></p>
              <TextInput v-model="broadcast.name" label="Name" rules="required" name="name"
                :readonly="!broadcastEditable" />
              <ValidationProvider v-slot="{ failed, errors }" name="keyword">
                <div class="form-group">
                  <label>Keywords</label>
                  <multiselect v-model="broadcast.keywords" :options="keywords" :multiple="true"
                    :class="{ 'is-invalid': failed }" placeholder="Select keywords"
                    :disabled="!broadcastEditable || resendEditable"></multiselect>
                  <b-form-invalid-feedback v-if="errors.length">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>

                  <div v-if="broadcastEditable" class="mt-2 pl-2">
                    <b-spinner v-if="loadingCredit" small></b-spinner>
                    <div v-if="!loadingCredit && messageCredit">
                      <div>
                        <div v-if="messageCredit && messageCredit.credit">
                          <span class="text-success">{{ messageCredit.credit }} contacts are available.<br /></span>
                        </div>
                        <div v-else>
                          <span class="text-danger">ALERT: No contacts are available for this broadcast!</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Exclude these people from the broadcast</label>
                  <multiselect v-model="broadcast.exclude_keywords" :options="listKeywords" :multiple="true"
                    :class="{ 'is-invalid': failed }" placeholder="Select keywords" label="name" track-by="id"
                    :disabled="!broadcastEditable || resendEditable"></multiselect>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-12 mb-4">
                      <b-input-group-append>
                        <b-button style="border-radius:0.3rem 0 0 0.3rem;" :disabled="!broadcastEditable"
                          :variant="broadcast.send_type === 'now' ? 'primary' : 'outline-dark'"
                          @click="chooseSentAt('now')">Send Now</b-button>
                        <b-button style="border-left: none;border-radius:0 0.3rem 0.3rem 0;"
                          :disabled="!broadcastEditable"
                          :variant="broadcast.send_type === 'later' ? 'primary' : 'outline-dark'"
                          @click="chooseSentAt('later')">Send Later</b-button>
                      </b-input-group-append>
                    </div>
                    <div v-if="broadcast.send_type === 'later'" class="col-md-12">
                      <div class="row">
                        <div class="col-6">
                          <flat-pickr v-model="broadcast.date" :config="dateTimePicker" class="form-control"
                            placeholder="Pick a date"></flat-pickr>
                        </div>
                        <div class="col-6">
                          <TimePicker v-model="broadcast.time" :night-off="true" />
                        </div>
                        <div class="col-md-12 mt-3">
                          <SelectInput v-model="broadcast.timezone" :options="timezones" label="Timezone" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div v-if="showEmailOptions" class="form-group">
                    <label>Email account <span v-b-tooltip.hover class="cursor-pointer"
                        title="You can leave this blank to use system default email client."><i
                          class="uil uil-question-circle"></i></span></label>
                    <multiselect v-model="broadcast.options.integration_user" :options="emailOptions"
                      :multiple="false" :allow-empty="false" track-by="id" placeholder="Select Account" label="name"
                      :disabled="!broadcastEditable">
                    </multiselect>
                  </div>
                  <div v-if="allowReplyTracking" class="d-block my-4">
                    <div class="row">
                      <div class="col-md-4">
                        <b-form-checkbox v-model="replyTracking" @change="onReplyTrackingChange">
                          Reply Tracking <i v-b-tooltip.hover
                          title="Track when a contact replies to your email. The Reply-To address in your message will be changed for this feature to work properly. Your From address will not be changed."
                          class="uil uil-question-circle"></i>
                        </b-form-checkbox>
                      </div>
                      <div v-if="replyTracking" class="col-md-8">
                        <div class="form-group">
                          <label>Automation <span v-b-tooltip.hover class="cursor-pointer"
                              title="These automations will be triggered where there is a reply from contact."><i
                                class="uil uil-question-circle"></i></span></label>
                          <multiselect v-model="selectedAutomations" :options="automationList" :multiple="true"
                            track-by="id" placeholder="Select Automations" label="name"></multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="broadcastEditable" class="form-group">
                    <MergeFieldsGroup :text-field="$refs.message" :show-redemption="true" :hide-expire-date="true"
                      @selected="appendField" />
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput v-model="broadcast.options.from_name" label="From Name" name="fromName"
                        :disabled="!broadcastEditable || resendEditable"></TextInput>
                    </div>
                    <div v-if="!allowReplyTracking || !replyTracking" class="col-md-6">
                      <TextInput v-model="broadcast.options.from_email" label="Reply To" name="replyTo"
                        :disabled="!broadcastEditable || resendEditable"></TextInput>
                    </div>
                  </div>
                </div>
                <TextInput v-model="broadcast.subject" label="Subject" name="subject" rules="required" :disabled="!broadcastEditable"></TextInput>
                <div class="b-block">
                  <label class="form-control-label">Preheader Text <i v-b-tooltip.hover
                      title="A snippet of text that appears after the email subiect line. If this field is left blank the first line of your email will display. Preheader text Replay is live"
                      class="uil uil-question-circle"></i></label>
                  <TextInput v-model="broadcast.options.preheader_text" name="preheader" :disabled="!broadcastEditable"></TextInput>
                </div>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <b-modal v-model="replyTrackingModal" title="Reply Tracking Confirmation">
      <p>The Reply-To address in your message will be changed for this feature to work properly. Your From address will not be changed.</p>
      <p>Important replies will be forwarded to your original Reply-To email address or From email address if you did not specify a Reply-To address.</p>
      <p>Do you wish to continue?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="replyTrackingModal = false">Cancel</b-button>
        <b-button variant="primary" @click="onReplyTrackingConfirm">Continue</b-button>
      </template>
    </b-modal>
  </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment-timezone'

export default {
  components: {
    Multiselect,
  },

  props: {
    broadcast: {
      type: Object,
      default: null,
    },
    pageType: {
      type: String,
      default: 'create',
    }
  },

  data() {
    return {
      loadingCredit: false,
      messageCredit: null,
      keywords: [],
      dateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
      },
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      testNumber: null,
      listKeywords: [],
      isResendPage: false,
      isResend: false,
      isDuplicate: false,
      resendType: 1,
      prevBroadcastId: null,
      emailOptions: [],
      replyTracking: false,
      replyTrackingModal: false,
      selectedAutomations: [],
      automationList: [],
      totalEmailsMonthly: 0,
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    business() {
      return this.$store.getters['auth/business']
    },

    userTimezone() {
      return this.business ? this.business.timezone : moment.tz.guess()
    },

    broadcastEditable() {
      return this.isDuplicate || (!this.broadcast.status || ['scheduled', 'draft', 'paused'].includes(this.broadcast.status)) || this.resendEditable
    },

    resendEditable() {
      return this.isResendPage || this.isResend && (!this.broadcast.status || ['scheduled', 'draft', 'paused'].includes(this.broadcast.status))
    },

    agencyEmail() {
      return this.business && this.business.agency && this.business.agency.email;
    },

    msgCount() {
      if (this.broadcast.images && this.broadcast.images.length)
        return 3;
      return Math.ceil((this.broadcast.message.length + 21) / 160)
    },

    msgCreditUsed() {
      if (this.messageCredit && this.messageCredit.credit)
        return this.messageCredit.credit * this.msgCount
      return 0
    },

    showEmailOptions() {
      return this.emailOptions && this.emailOptions.length > 1
    },

    hasSendgridIntegration() {
      return this.user && this.user.business && this.user.business.has_integrations.sendgrid
    },

    allowEmailIntegration() {
      return this.$store.getters['auth/allowEmailIntegration']
    },

    allowReplyTracking() {
      return this.broadcast.options.integration_user && this.broadcast.options.integration_user.type === 2 || 
        !this.broadcast.options.integration_user && this.allowEmailIntegration && this.hasSendgridIntegration
    },
    
    showLimitNotification() {
      return this.business && this.business.is_admin_sendgrid && this.totalEmailsMonthly > this.business.email_limit_per_month
    }
  },

  watch: {
    'broadcast.keywords': function (val) {
      this.broadcast && this.getMessageCredit()
    },
    'broadcast.exclude_keywords': function (val) {
      this.broadcast && this.getMessageCredit()
    },
  },

  mounted() {
    this.isResendPage = this.$route.name === 'business.broadcasts.resend'
    this.isDuplicate = this.$route.name === 'business.broadcasts.duplicate'
    
    if (!this.isDuplicate) {
      if (this.isResendPage) {
        this.prevBroadcastId = this.broadcast.id
        this.isResend = true;
        this.resendType = this.$route.query.type
      } else {
        if (this.broadcast && this.broadcast.options && this.broadcast.options.prev_id) {
          this.prevBroadcastId = this.broadcast.options.prev_id
        }
        if (this.prevBroadcastId && this.broadcast.options.is_resend) {
          this.isResend = this.broadcast.options.is_resend
        }

        if (this.isResend && this.prevBroadcastId && this.broadcast.options.resend_type) { 
          this.resendType = this.broadcast.options.resend_type
        }
      }
    }

    this.replyTracking = this.broadcast.options.reply_tracking
    this.selectedAutomations = this.broadcast.automations

    this.getKeywords()
    this.getEmailOptions()
    this.getMessageCredit()
    this.getAutomations()
    this.getTotalEmailMonthly()
  },

  methods: {
    onReplyTrackingConfirm() {
      this.replyTrackingModal = false
      this.replyTracking = true
    },
    onReplyTrackingChange() {
      if (this.replyTracking)
        this.replyTrackingModal = true
      this.replyTracking = false
    },
    getTotalEmailMonthly() {
      this.$store.dispatch('business/getTotalEmailMonthly')
        .then(res => {
          this.totalEmailsMonthly = res.data.total_emails_monthly
        })
        .catch(() => {
        })
    },
    getAutomations() {
      let params = {
        queries: {
          per_page: 1000,
          start_trigger: 'email-reply',
        }
      }
      this.$store.dispatch('automation/getAll', params)
        .then(res => {
          this.automationList = res.data.filter(item => item.active)
            .map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })
        })
        .catch(() => {
        })
    },
    getEmailOptions() {
      this.$store.dispatch('integration/selectOptions')
        .then(options => {
          this.emailOptions = options
          if (options && options.length === 1) {
            this.broadcast.options.integration_user = options[0]
          }
        })
        .catch(() => { })
    },

    onBack() {
      this.$router.push({ name: 'business.broadcasts.index', query: { type: this.broadcast.provider } })
    },

    getKeywords() {

      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords.map(c => c.name)
          this.listKeywords = keywords
        })
        .catch(() => {
        })
    },

    getMessageCredit() {
      this.loadingCredit = true

      let param = {
        keywords: this.broadcast.keywords,
        excludes: this.broadcast.exclude_keywords,
        provider: this.broadcast.provider,
      }
      if (this.resendEditable) {
        param = {
          ...param,
          isResend: this.isResend,
          resendType: this.resendType,
          id: this.prevBroadcastId,
        }
      }

      this.$store
        .dispatch('broadcast/messageCredit', param)
        .then((messageCredit) => {
          this.messageCredit = messageCredit
          this.loadingCredit = false
        })
        .catch(() => {
          this.loadingCredit = false
        })
    },

    chooseSentAt(sendType) {
      this.broadcast.send_type = sendType
    },

    appendField(field) {
      document.execCommand('insertText', false, field)
    },

    async validateStep() {
      let stepValid = false

      await this.$refs.broadcastForm.validate().then(async (isValid) => {
        if (isValid) {
          if (this.broadcastEditable) {
            stepValid = await this.updateOrCreate()
          } else {
            stepValid = true 
          }
        }
      })

      return stepValid
    },

    async updateOrCreate() {
      let stepValid = false
      let isNew = true;
      this.$emit('loading', true)
      this.broadcast.save_draft = true
      let action = 'broadcast/create'
      if (this.pageType === 'update' && this.broadcast.id) {
        action = 'broadcast/update'
        isNew = false
      }

      if (this.broadcast.send_type === 'later') {
        this.broadcast.scheduled_at = moment.tz(this.broadcast.date + ' ' + this.broadcast.time, "YYYY-MM-DD hh:mm A", this.broadcast.timezone || this.business.timezone).utc().format();
      } else {
        delete this.broadcast.scheduled_at
      }

      if (this.resendEditable) {
        this.broadcast.options['prev_id'] = this.prevBroadcastId
        this.broadcast.options['is_resend'] = this.isResend
        this.broadcast.options['resend_type'] = this.resendType
      } else {
        delete this.broadcast.options['prev_id']
        delete this.broadcast.options['is_resend']
        delete this.broadcast.options['resend_type']
      }

      this.broadcast.options['reply_tracking'] = this.replyTracking
      this.broadcast.automations = this.selectedAutomations

      await this.$store
        .dispatch(action, this.broadcast)
        .then((res) => {
          this.broadcast.id = res.id;
          this.$emit('done', {
            broadcast: this.broadcast,
            create: isNew,
          })
          this.$emit('loading', false)
          stepValid = true
        })
        .catch(() => {
          this.$emit('loading', false)
        })
      return stepValid
    },
  },
}
</script>
  
<style lang="scss">
.email-create-step {
  max-width: 800px;

  .flatpickr-input {
    background: #FFF !important;
  }
}
</style>