<template>
  <div id="fullscreen-editor" class="card builder">
    <div class="builder-toolbar-wrapper d-flex justify-content-start">
      <div id="builder-toolbar">
      </div>
      <div class="builder-toolbar-custom d-flex">
        <div class="mt-1">
          <MergeFieldsGroup :show-redemption="true" :hide-expire-date="true" :hide-title="true" margin-bottom-emtpy
            @selected="appendField" />
        </div>
        <div class="d-inline-block mt-1">
          <button type="button" class="btn btn-outline-dark ml-2" @click="showModal">
            <i class="uil uil-upload"></i> Add Attachment
            <span v-if="imageLength" class="badge position-absolute rounded-pill top-0 start-100 bg-dark text-white">
              {{ imageLength }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body builder-body">
      <ValidationObserver ref="builderForm">
        <div :style="{ textAlign: `${broadcast.options.align}`, marginTop: '30px' }">
          <div class="builder-content" :style="{ width: `${broadcast.options.width}px` }">
            <TextEditorAction ref="editor" v-model="broadcast.message" :object="broadcast" rules="required" :options="editorOptions"
              hide-upload toolbar-type="full">
            </TextEditorAction>
            <div class="d-block text-center">
              <span class="d-block">This email was sent to <a href="javascript:;">example@gmail.com</a></span>
              <a class="d-block mt-2" href="javascript:;">Unsubscribe</a>
              <span class="d-block m-2">©{{ currentYear }} {{ businessName }}. All rights reserved. {{ businessAddress
              }}</span>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    broadcast: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isResend: false,
      resendType: 1,
      emailOptions: [],
      editorOptions: {
        scrollableContainer: '#fullscreen-editor',
        toolbarContainer: '#builder-toolbar',
        events: {
          // 'froalaEditor.initialized': function (e, editor) {
          //   editor.toolbar.hide()
          // },
          // 'froalaEditor.focus': function (e, editor) {
          //   editor.toolbar.show()
          // },
          // 'froalaEditor.blur': function (e, editor) {
          //   editor.toolbar.hide()
          // }
        },
      }
    }
  },

  computed: {
    business() {
      return this.$store.getters['auth/business']
    },

    currentYear() {
      return new Date().getFullYear();
    },

    businessName() {
      return this.business && this.business.name
    },

    businessAddress() {
      return this.business && this.business.address
    },

    imageLength() {
      if (this.broadcast && this.broadcast.images)
        return this.broadcast.images.length
      return 0
    },
  },

  mounted() {
    this.isResend = this.$route.name === 'business.broadcasts.resend'
    if (this.isResend) {
      this.resendType = this.$route.query.type
    }
  },

  methods: {
    appendField(field) {
      this.broadcast.message += field
      document.execCommand('insertText', false, field)
    },

    showModal() {
      this.$refs.editor.showModal()
    },

    async validateStep() {
      let stepValid = false

      await this.$refs.builderForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = true
        }
      })

      return stepValid && !!this.broadcast.message
    },
  }
}
</script>

<style lang="scss" scoped>
.builder-content {
  max-width: 100%;
  display: inline-block;
}

.builder-toolbar-wrapper {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 49px;
  border-bottom: 1px solid #ccc;

  #builder-toolbar {
    width: 860px;
  }
  .builder-toolbar-custom {
    flex: 1 0 auto; 
  }
}

.builder-body {
  margin-top: 100px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
</style>